import { colors } from "./colors"

const sharedTextStyles = {
  H0: {
    fontSize: { base: "5.0rem", lg: "6.0rem" },
    lineHeight: "120%",
    letterSpacing: "-0.185rem",
    color: colors.black[100],
  },
  H1: {
    fontSize: { base: "4.1rem", lg: "5.0rem" },
    lineHeight: "130%",
    letterSpacing: "-0.085rem",
    color: colors.black[100],
  },
  H2: {
    fontSize: { base: "3.4rem", lg: "4.1rem" },
    lineHeight: "140%",
    letterSpacing: "-0.035rem",
    color: colors.black[100],
  },
  H3: {
    fontSize: { base: "2.9rem", lg: "3.4rem" },
    lineHeight: "150%",
    letterSpacing: "0rem",
    color: colors.black[100],
  },
  H4: {
    fontSize: { base: "2.4rem", lg: "2.9rem" },
    lineHeight: "150%",
    letterSpacing: "0.015rem",
    color: colors.black[100],
  },
  H5: {
    fontSize: { base: "2.0rem", lg: "2.4rem" },
    lineHeight: "150%",
    letterSpacing: "0.015rem",
    color: colors.black[100],
  },
  B1: {
    fontSize: { base: "1.5rem", lg: "1.6rem" },
    lineHeight: "160%",
    letterSpacing: "0.015rem",
    color: colors.black[200],
  },
  B2: {
    fontSize: { base: "1.3rem", lg: "1.4rem" },
    lineHeight: "170%",
    letterSpacing: "0.015rem",
    color: colors.black[300],
  },

  //overline
  OL: {
    fontSize: { base: "1.1rem", lg: "1.2rem" },
    lineHeight: "170%",
    letterSpacing: "0.015rem",
    color: colors.primary[400],
  },

  //field label
  FL: {
    fontSize: { base: "0.9rem", lg: "0.9rem" },
    lineHeight: "170%",
    letterSpacing: "0.015rem",
    color: colors.black[400],
  },
}

export const fonts = {
  body: "Public Sans, sans-serif",
  heading: "Public Sans, sans-serif",
  mono: "Public Sans, sans-serif",
}

export const textStyles = {
  H0B: {
    ...sharedTextStyles.H0,
    fontWeight: 800,
  },
  H0R: {
    ...sharedTextStyles.H0,
    fontWeight: 400,
  },
  H1B: {
    ...sharedTextStyles.H1,
    fontWeight: 800,
  },
  H1R: {
    ...sharedTextStyles.H1,
    fontWeight: 500,
  },
  H2B: {
    ...sharedTextStyles.H2,
    fontWeight: 700,
  },
  H2R: {
    ...sharedTextStyles.H2,
    fontWeight: 400,
  },
  H3B: {
    ...sharedTextStyles.H3,
    fontWeight: 700,
  },
  H3R: {
    ...sharedTextStyles.H3,
    fontWeight: 400,
  },
  H4B: {
    ...sharedTextStyles.H4,
    fontWeight: 700,
  },
  H4R: {
    ...sharedTextStyles.H4,
    fontWeight: 500,
  },
  H5B: {
    ...sharedTextStyles.H5,
    fontWeight: 700,
  },
  H5R: {
    ...sharedTextStyles.H5,
    fontWeight: 400,
  },
  B1B: {
    ...sharedTextStyles.B1,
    fontWeight: 600,
  },
  B1R: {
    ...sharedTextStyles.B1,
    fontWeight: 400,
  },
  B2B: {
    ...sharedTextStyles.B2,
    fontWeight: 600,
  },
  B2R: {
    ...sharedTextStyles.B2,
    fontWeight: 400,
  },
  OLB: {
    ...sharedTextStyles.OL,
    fontWeight: 600,
  },
  OLR: {
    ...sharedTextStyles.OL,
    fontWeight: 500,
  },
  FLB: {
    ...sharedTextStyles.FL,
    fontWeight: 800,
  },
  FLR: {
    ...sharedTextStyles.FL,
    fontWeight: 400,
  },
}
