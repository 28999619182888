import { inputAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { textStyles } from "../typography"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    ...textStyles.B1R,
    py: 9,
    px: 6,
    borderRadius: 8,
    _placeholder: { opacity: 0 },
    zIndex: "base",
    _selected: {
      outline: "none",
    },
  },
})

export const inputTheme = defineMultiStyleConfig({
  baseStyle,

  variants: {
    default: {},
  },
})
