export const colors = {
  primary: {
    //darkest
    100: "#101F4D",
    200: "#223E94",
    300: "#4257C6",
    400: "#5C76FF",
    500: "#5C76FFAB",
    600: "#5C76FF63",
    700: "#5C76FF30",
    800: "#5C76FF12",
    900: "#5C76FF08",
  },
  secondary: {
    //darkest
    100: "#946F6F",
    200: "#D4B1B1",
    300: "#F0CECE",
    400: "#FFE5E5",
    500: "#FFE5E5AB",
    600: "#FFE5E563",
    700: "#FFE5E530",
    800: "#FFE5E512",
    900: "#FFE5E508",
  },
  black: {
    //darkest
    100: "#292C40",
    200: "#292C40D6",
    300: "#292C40AB",
    400: "#292C4085",
    500: "#292C4063",
    600: "#292C4047",
    700: "#292C4030",
    800: "#292C401F",
    900: "#292C4012",
    1000: "#292C4008",
  },
  white: {
    //darkest
    100: "#FFFFFF",
    200: "#FFFFFFD6",
    300: "#FFFFFFAB",
    400: "#FFFFFF85",
    500: "#FFFFFF63",
    600: "#FFFFFF47",
    700: "#FFFFFF30",
    800: "#FFFFFF1F",
    900: "#FFFFFF12",
    1000: "#FFFFFF08",
  },
  success: {
    //darkest
    100: "#3E725F",
    200: "#6AF09E",
    300: "#6AF09EAB",
    400: "#6AF09E63",
    500: "#6AF09E1F",
  },
  warning: {
    //darkest
    100: "#77583B",
    200: "#FFC852",
    300: "#FFC852AB",
    400: "#FFC85263",
    500: "#FFC8521F",
  },
  error: {
    //darkest
    100: "#75364F",
    200: "#FF4D67",
    300: "#FF4D67AB",
    400: "#FF4D6763",
    500: "#FF4D671F",
  },
}
