import { ChakraProvider, ChakraTheme, extendTheme } from "@chakra-ui/react"
import { colors } from "./colors"
import { fonts, textStyles } from "./typography"
import { accordionTheme } from "./componentThemes/accordionTheme"
import { modalTheme } from "./componentThemes/modalTheme"
import { buttonTheme } from "./componentThemes/buttonTheme"
import { inputTheme } from "./componentThemes/InputTheme"
import { radioTheme } from "./componentThemes/radioTheme"

export const themeObject: Partial<ChakraTheme> = {
  colors,
  fonts,
  textStyles,

  components: {
    Accordion: accordionTheme,
    Modal: modalTheme,
    Button: buttonTheme,
    Input: inputTheme,
    Radio: radioTheme,
  },
  shadows: {
    xs: "0px 2px 4px -2px rgba(0, 0, 0, 0.18)",
    sm: "0px 6px 8px -4px rgba(0, 0, 0, 0.10)",
    md: "0px 8px 16px -4px rgba(0, 0, 0, 0.10)",
    lg: "0px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    xlg: "0px 20px 36px -1px rgba(0, 0, 0, 0.05)",
    xxlg: "0px 27px 50px -3px rgba(0, 0, 0, 0.04)",
    xxxlg: "0px 37px 80px -8px rgba(0, 0, 0, 0.07)",
  },
}

export const extendedTheme = extendTheme(themeObject)

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ChakraProvider
    theme={extendedTheme}
    toastOptions={{
      defaultOptions: {
        duration: 2000,
        position: "top",
        containerStyle: {
          textStyle: "B1R",
          color: "white",
          boxShadow: "none",
        },
      },
    }}
  >
    {children}
  </ChakraProvider>
)
