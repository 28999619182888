import { modalAnatomy as parts } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: "black.300", //change the background
  },
  body: {
    textStyle: "B1R",
    p: 10,
  },
  closeButton: {
    boxSize: 12,
    fontSize: 12,
    bg: "black.1000",
    color: "black.400",
    top: 4,
    right: 4,
  },
  dialogContainer: { p: 4, w: "100%" },
  footer: { p: 10 },
  header: { pt: 16, px: 10, bg: "white.100" },

  dialog: {
    minW: { base: "100%", md: 400, lg: 500 },
    maxW: "600px",
    borderRadius: 12,
    overflow: "hidden",
    bg: `white.100`,
  },
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
})
