import { accordionAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  root: {},

  container: {},
  button: {
    textStyle: "B1B",
    py: 8,

    _hover: {
      bgColor: "black.1000",
    },
  },
  panel: { textStyle: "B1R", color: "black.300", pb: 6 },
  icon: { boxSize: "24px", opacity: 0.5 },
})

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  //  defaultProps: {},
  variants: {
    getStarted: {
      root: {},
      container: {
        overflow: "hidden",
        bg: "black.1000",
        borderRadius: 16,
        mb: 6,
        borderTop: "none",
        borderBottom: "none",
      },
      button: {},
      panel: {
        px: 10,
        pt: 0,
        textStyle: "B2R",
      },
      icon: {},
    },
  },
})
