import { radioAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { textStyles } from "../typography"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
})

export const radioTheme = defineMultiStyleConfig({
  baseStyle,

  defaultProps: {
    variant: "default",
    colorScheme: "primary",
  },

  variants: {
    default: {
      control: {
        boxSize: 10,
        ...textStyles.B2R,
      },
      label: {
        ...textStyles.B2R,
        ml: 6,
      },
      container: {},
    },
  },
})
