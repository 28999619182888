import { ButtonProps, defineStyleConfig } from "@chakra-ui/react"
import { textStyles } from "../typography"

const commonStyles: ButtonProps = {
  ...textStyles.B1B,
  p: [8, 8, 10],
  py: [9, 9, 10],
  fontSize: ["1.6rem", "1.6rem", "1.8rem"],
  borderRadius: 8,
  _focus: {
    boxShadow: "none",
  },
}

export const buttonTheme = defineStyleConfig({
  baseStyle: {},
  defaultProps: {
    variant: "primary",
  },

  variants: {
    primary: {
      ...commonStyles,
      color: "white.100",
      bg: "primary.400",

      _hover: {
        bg: "primary.300",
      },
      _focus: {
        boxShadow: "none",
      },
    },
    primaryWhite: {
      ...commonStyles,
      color: "primary.400",
      bg: "white.100",
      _hover: {
        bg: "white.800",
        color: "white.100",
      },
    },

    secondary: {
      ...commonStyles,
      color: "primary.200",
      bg: "primary.900",

      _hover: {
        bg: "primary.800",
      },
    },
    tertiary: {
      ...commonStyles,
      color: "primary.300",
      bg: "none",

      _hover: {
        color: "primary.100",
      },
    },
  },
})
